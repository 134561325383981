import { Rectangle } from "../interfaces/textLayer";
import lodash from "lodash";

const BASE_BIAS = 0.5;
const TOLERANCE = 10;

const roundToTolerance = (
  top: number,
  tolerance: number,
  bias: number
): number => {
  return Math.floor(top / tolerance + bias) * tolerance;
};

const roundRotation = (angle: number): number => {
  if (Math.abs(angle) > 10) {
    return Math.floor(angle);
  }

  return 0;
};

const isTextHorizontal = (avgRotation: number): boolean => {
  // Assuming a rotation of 0 or 90 degrees indicates horizontal or vertical text respectively
  const horizontalThreshold = 45; // You might want to adjust this threshold based on your use case

  // If the average rotation is closer to 0 or 180, it's horizontal, otherwise vertical
  return (
    Math.abs(avgRotation) < horizontalThreshold ||
    Math.abs(avgRotation - 180) < horizontalThreshold
  );
};

export const calculateBoundingBoxForAnnotationGroup = (
  rectangles: Array<Rectangle>,
  scale: number
): Rectangle => {
  const avgRotation = lodash.meanBy(rectangles, "rotation");

  if (isTextHorizontal(Math.abs(avgRotation))) {
    return {
      ...calcBoundingBoxForHorizontalText(rectangles, scale),
      rotation: roundRotation(avgRotation),
    };
  }

  return {
    ...calcBoundingBoxForVerticalText(rectangles, scale),
  };
};

const calcBoundingBoxForVerticalText = (
  rectangles: Array<Rectangle>,
  scale: number
): Rectangle => {
  // Group the rectangles based on the left and width property with a tolerance of 10 pixels
  const groupedByLeft = lodash(rectangles)
    .groupBy((rect) =>
      roundToTolerance(
        Math.floor(rect.left) + Math.floor(rect.width),
        TOLERANCE,
        BASE_BIAS * scale
      )
    )
    .mapValues((group) => lodash.sortBy(group, "left"))
    .value();

  const sortedGroups = Object.values(groupedByLeft);

  const smallestLeft = Math.min(...rectangles.map((r) => r.left));
  const smallestTop = Math.min(...rectangles.map((r) => r.top));

  // Calculate the width
  let totalWidth = 0;
  let previousGroupRight = 0;

  sortedGroups.forEach((group, index) => {
    const firstRect = group[0];
    if (index === 0) {
      totalWidth += firstRect.width;
      previousGroupRight = firstRect.left + firstRect.width;
    } else {
      const distance = firstRect.left - previousGroupRight;
      totalWidth += Math.max(0, distance) + firstRect.width;
      previousGroupRight = firstRect.left + firstRect.width;
    }
  });

  // Find the group with the largest sum of heights
  const groupWithLargestHeightSum = sortedGroups
    .reduce(
      (maxGroup, group) =>
        group.reduce((sum, rect) => sum + rect.height, 0) >
        maxGroup.reduce((sum, rect) => sum + rect.height, 0)
          ? group
          : maxGroup,
      sortedGroups[0]
    )
    .sort((a, b) => a.top - b.top);

  // Calculate the height
  let totalHeight = 0;
  let previousGroupBottom = 0;

  groupWithLargestHeightSum.forEach((rect, index) => {
    if (index === 0) {
      totalHeight += rect.height;
      previousGroupBottom = rect.top + rect.height;
    } else {
      const distance = rect.top - previousGroupBottom;
      totalHeight += distance + rect.height;
      previousGroupBottom = rect.top + rect.height;
    }
  });

  return {
    left: smallestLeft,
    top: smallestTop,
    width: totalWidth,
    height: totalHeight,
    rotation: 0,
  };
};

const calcBoundingBoxForHorizontalText = (
  rectangles: Array<Rectangle>,
  scale: number
): Rectangle => {
  // Group the rectangles based on the top + height property (=bottom) with a tolerance of 10 pixels -> for example 55px and 65px are 1 group
  const groupedByTop = lodash(rectangles)
    .groupBy((rect) =>
      roundToTolerance(Math.floor(rect.top), TOLERANCE, BASE_BIAS * scale)
    )
    .mapValues((group) => lodash.sortBy(group, "top"))
    .value();

  const sortedGroups = Object.values(groupedByTop);

  const smallestLeft = Math.min(...rectangles.map((r) => r.left));
  const smallestTop = Math.min(...rectangles.map((r) => r.top));

  // Calculate the height
  let totalHeight = 0;
  let previousGroupBottom = 0;

  sortedGroups.forEach((group, index) => {
    const firstRect = group[0];
    if (index === 0) {
      totalHeight += firstRect.height;
      previousGroupBottom = firstRect.top + firstRect.height;
    } else {
      const distance = firstRect.top - previousGroupBottom;
      totalHeight += Math.max(0, distance) + firstRect.height;
      previousGroupBottom = firstRect.top + firstRect.height;
    }
  });

  // Find the group with the largest sum of widths
  const groupWithLargestWidthSum = sortedGroups
    .reduce(
      (maxGroup, group) =>
        group.reduce((sum, rect) => sum + rect.width, 0) >
        maxGroup.reduce((sum, rect) => sum + rect.width, 0)
          ? group
          : maxGroup,
      sortedGroups[0]
    )
    .sort((a, b) => a.left - b.left);

  // Calculate the width
  let totalWidth = 0;

  groupWithLargestWidthSum.forEach((rect, index) => {
    const { left, width } = rect;
    if (index === 0) {
      totalWidth += width;
    } else {
      const previousRect = groupWithLargestWidthSum[index - 1];
      const previousRight = previousRect.left + previousRect.width;
      totalWidth += Math.max(0, left - previousRight) + width;
    }
  });

  return {
    left: smallestLeft,
    top: smallestTop,
    width: totalWidth,
    height: totalHeight,
    rotation: 0,
  };
};

export const recalculateBoundingBox = (
  coordinates: Rectangle,
  oldScale: number,
  newScale: number
): Rectangle => {
  return {
    left: (coordinates.left / oldScale) * newScale,
    top: (coordinates.top / oldScale) * newScale,
    width: (coordinates.width / oldScale) * newScale,
    height: (coordinates.height / oldScale) * newScale,
    rotation: coordinates.rotation,
  };
};
